import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useFlightPathUser } from "../../../../core/auth_insight";
import { GridTypes } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactReportingViewComponent } from "./ImpactReportingView_component";
import { ImpactReportingViewModel } from "./ImpactReportingView_model";

export interface ImpactReportingViewProps {}

export const ImpactReportingView: React.FC<ImpactReportingViewProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  const authUser = useFlightPathUser();
  const [model] = useState(
    () =>
      new ImpactReportingViewModel(
        organisationId,
        +projectId,
        authUser,
        navigate,
        GridTypes.IMPACTS_REPORTS_LINK_MODAL_GRID
      )
  );

  useEffect(() => {
    model.onMount();
    return () => model.onUnmount();
    // eslint-disable-next-line
  }, [model]);

  return (
    <>
      {model.isLoading ? (
        <PositionedSpinner></PositionedSpinner>
      ) : (
        <ImpactReportingViewComponent organisationId={organisationId} projectId={+projectId} model={model} />
      )}
    </>
  );
});
